<template>
  <div class="space">
    <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
    <div class="left">
      <!-- <div class="title">{{spaceData.building_name}}</div> -->
      <div class="types">
        <div :class="[activeIndex == index?'active-type':'','type']" v-for="(item,index) in spaceData.content"
          :key="index" @click="handleChangeContent(index)">
          <div class="left-type">
            <div class="area">{{item.title}}</div>
            <!-- <div class="loca">{{item.subtitle}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="content" id="content" v-html="itemData.content" @click="handleBigImg">
      </div>
    </div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/@vant/touch-emulator"></script>
<script>
  import { ImagePreview } from 'vant'
  import '@vant/touch-emulator'
  export default {
    data() {
      return {
        spaceData: {},
        itemData: {},
        activeIndex: 0,
        beforePage:false
      };
    },
    methods: {
      handleGoBack() {
        if(!this.beforePage){
          this.$router.go(-1)
        }else{
          this.$router.push({
            path:"/"
          })
        }
      },
      getData() {
        this.$axios({
          method: "POST",
          url: "api/v5/panorama/channelDetails",
          data: {
            panorama_id: this.$route.query.panorama_id,
            channel_id: this.$route.query.channel_id
          }
        }).then(res => {
          this.spaceData = res.data.data;
          if (res.data.data.content.length > 0) {
            this.itemData = res.data.data.content[0];
            // document.getElementById('content').appendChild(res.data.data.content[0].content)
          }
        })
      },
      handleChangeContent(index) {
        this.activeIndex = index;
        this.itemData = this.spaceData.content[index]
      },
      handleBigImg () {
        const src = document.getElementById('content').getElementsByTagName('img')[0].src
        ImagePreview({
          images:[src],
          showIndex: false,
          closeable : false,
          maxZoom:7
        })
      }
    },
    beforeRouteEnter(to, from, next) {
      if (from.name == null) {
        next(vm => {
          vm.beforePage = true
        })
      } else {
        next()
      }
    },
    created() {
      this.getData()
    }
  };
</script>
<style>
  #content .image {
    margin: 0;
    width: 100%;
    height: auto;
  }

  #content .image img {
    width: 100%;
    height:auto;
  }
  .van-image{
    overflow-y: auto;
  }
  /* .van-image__img{
    overflow-y: auto;
    object-fit: fill !important;
  } */
</style>
<style scoped lang='scss'>
  .space {
    width: 100vw;
    height: 100vh;
    background: #292b2d;
    overflow:hidden;
    .go-back {
      position: absolute;
      width: 44px;
      height: 36px;
      top: 77px;
      left: 72px;
      cursor: pointer;
      z-index: 2;
    }

    .left {
      position: absolute;
      top: 190px;
      left:136px;
      width: 300px;
      height: calc(100vh - 290px);
      text-align: left;
      overflow: hidden;

      /* .title {
        margin-bottom:43px;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 49px;
      } */

      .types {
        width: 100%;
        height: calc(100vh - 320px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .type {
          padding:37px 0 17px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #797A7B;
          line-height: 49px;

        }

        .active-type {
          position:relative;
         
          color: #FFFFFF;
          &:before{
            position:absolute;
            bottom:0;
            left:0;
            content:"";
            width:74px;
            height:5px;
            background:#fff;
          }
        }
      }
    }
    .right{
      display: flex;
      justify-content: center;
      /* align-items: center; */
      /* margin-left:436px; */
      margin:30px 0 0px 436px;
      width:calc(100vw - 436px);
      height:100vh;
      box-sizing: border-box;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .content{
        width:750px;
        height:auto;
      }
    }
  }
</style>